var prodcat = prodcat || {};
var site = site || {};

site.direction = site.direction || {};
(function ($) {
  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $thisProd = $(this);
    var $prodImage = $thisProd.find('.js-product-brief-image');
    var $prodImageAlt = $thisProd.find('.js-product-brief-image-alt');
    var $prodAddToBag = $thisProd.find('.js-add-to-cart').not('.js-use-with-atb');
    var $sppLink = $thisProd.find('.js-spp-link');
    var $shadesCarousel = $thisProd.find('.js-filter-swatch-slider').length
      ? $thisProd.find('.js-filter-swatch-slider')
      : $thisProd.find('.js-product-brief-shades__grid');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      var sppHref = $sppLink.attr('href'); // new version of prodLink
      var skuBadge = skuData.hasOwnProperty('MISC_FLAG_TEXT') ? skuData.MISC_FLAG_TEXT : '';
      var shadeName = skuData.hasOwnProperty('SHADENAME') ? skuData.SHADENAME : '';
      var shadeRouteName = prodcat.ui.generateShadePath(skuBaseId);
      var priceSku = skuData.PRICE;

      if ($shadesCarousel.hasClass('slick-initialized') && !$shadesCarousel.hasClass('hidden')) {
        productBriefCarouselSkuSelect($shadesCarousel, skuBaseId);
      }

      // Update this product
      $thisProd.attr('data-sku-base-id', skuBaseId);
      updateProdImage($prodImage, $prodImageAlt, skuData);
      updateProdImageSrcsets($prodImage, $prodImageAlt, skuData);

      // updating add to bag button
      $prodAddToBag.attr('data-sku-base-id', skuBaseId);

      if (typeof sppHref !== 'undefined') {
        if (shadeName) {
          var sppHrefSplit = sppHref.split('#');

          sppHref = sppHrefSplit[0] + (!skuData['DEFAULT_SKU'] ? shadeRouteName : '');
          $sppLink.attr('href', sppHref);
        }
      }

      // updating sku level badge
      if (!!skuBadge) {
        $thisProd.find('.product-brief-shades__badge').html(skuBadge);
      } else {
        $thisProd.find('.product-brief-shades__badge').html('');
      }
      // updating shadename
      $thisProd.find('.product-brief-shades__name').html(shadeName);
      $prodAddToBag.attr('data-sku-base-id', skuBaseId);
      $thisProd.attr('data-price-sort', priceSku);
    }
  });

  $(document).on('product.init', '.js-product', function () {
    var $body = $('body');
    var $thisProd = $(this);
    var $mppPage = $body.find('.mpp-container');
    var $prodImage = $thisProd.find('.js-product-brief-image');
    var $prodImageAlt = $thisProd.find('.js-product-brief-image-alt');
    var skuBaseId = $thisProd.attr('data-sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if ($mppPage.length) {
      var $altImage = $thisProd.find('.js-product-brief__image-wrapper');

      Drupal.behaviors.productBriefV2.initAltImageCarousel($altImage);
    }
    if (!!skuData) {
      updateProdImageSrcsets($prodImage, $prodImageAlt, skuData);
    }
  });

  function productBriefCarouselSkuSelect($shadesCarousel, skuBaseId) {
    var $clonedParent = $();
    var $slide = $shadesCarousel.find('div[data-sku-base-id="' + skuBaseId + '"]');
    var slideNo = $slide.attr('index') || 0;
    var slidesCount = $shadesCarousel.attr('data-slides-count');

    $shadesCarousel.attr('data-initial-slide', slideNo).data('initialSlide', parseInt(slideNo, 10));
    $clonedParent = $shadesCarousel.closest('.slick-slide.slick-cloned');
    if ($clonedParent.length === 0) {
      $shadesCarousel.slickGoTo(parseInt((slideNo / slidesCount) + 1) * slidesCount - slidesCount);
    }
  }

  function updateProdImage($prodImage, $prodImageAlt, skuData) {
    if ($prodImage.hasClass('lazyload')) {
      // if the image was not loaded yet but has lazyload we need to update only data-src lazyload will set the src
      if (
        !!skuData.IMAGE_MEDIUM_COMBINED &&
        skuData.IMAGE_MEDIUM_COMBINED[0] !== $prodImage.attr('data-src')
      ) {
        $prodImage.attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[0]);
        $prodImageAlt.attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[1]);
      }
    } else {
      // if the image was already loaded by lazyload or lazyload is not used on this image
      // we need to update both data-src and src
      if (
        !!skuData.IMAGE_MEDIUM_COMBINED &&
        skuData.IMAGE_MEDIUM_COMBINED[0] !== $prodImage.attr('src')
      ) {
        $prodImage
          .attr('src', skuData.IMAGE_MEDIUM_COMBINED[0])
          .attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[0]);
        $prodImageAlt
          .attr('src', skuData.IMAGE_MEDIUM_COMBINED[1])
          .attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[1]);
      }
    }
  }

  function updateProdImageSrcsets($prodImage, $prodImageAlt, skuData) {
    var isProductImageResponsive =
      Drupal.settings.global_variables &&
      Drupal.settings.global_variables.product_responsive_images;
    var baseImagePath;
    var baseImageAltPath;
    var imageSrcset;
    var imageAltSrcset;
    var productBriefImageSrcsetSizes = [
      {
        size: '310',
        xDescriptor: '310w',
      },
      {
        size: '768',
        xDescriptor: '768w',
      },
    ];

    if (isProductImageResponsive && skuData.IMAGE_MEDIUM_COMBINED) {
      baseImagePath = skuData.IMAGE_MEDIUM_COMBINED[0] && skuData.IMAGE_MEDIUM_COMBINED[0].split('?')[0];
      baseImageAltPath = skuData.IMAGE_MEDIUM_COMBINED[1] && skuData.IMAGE_MEDIUM_COMBINED[1].split('?')[0];
      imageSrcset = '';
      imageAltSrcset = '';
      productBriefImageSrcsetSizes.forEach(imageSrcsetSize => {
        imageSrcset += `${baseImagePath}?width=${imageSrcsetSize.size}&height=${imageSrcsetSize.size} ${imageSrcsetSize.xDescriptor},`;
        imageAltSrcset += `${baseImageAltPath}?width=${imageSrcsetSize.size}&height=${imageSrcsetSize.size} ${imageSrcsetSize.xDescriptor},`;
      });
      imageSrcset = imageSrcset.slice(0, -1);
      imageAltSrcset = imageAltSrcset.slice(0, -1);

      if ($prodImage.hasClass('lazyload')) {
        $prodImage.attr('data-srcset', imageSrcset);
        $prodImageAlt.attr('data-srcset', imageAltSrcset);
      } else {
        $prodImage
          .attr('srcset', imageSrcset)
          .attr('data-srcset', imageSrcset);
        $prodImageAlt
          .attr('srcset', imageAltSrcset)
          .attr('data-srcset', imageAltSrcset);
      }
    }
  }

  Drupal.behaviors.productBriefV2 = {
    isMobile: false,
    attach: function (context) {
      if (
        navigator.userAgent.indexOf('MSIE ') !== -1 ||
        navigator.userAgent.indexOf('Trident/') !== -1
      ) {
        $('.js-product-brief', context).once('IE-min-height');
      }
      // Observable for reviews snippet text to be updated.
      var observer = new MutationObserver(function (mutations, observer) {
        mutations.forEach(function (mutation) {
          var $addedNodes = $(mutation.addedNodes);

          if ($addedNodes.length) {
            $addedNodes.find('.pr-category-snippet__total').each(function () {
              var $string = $(this);
              var $ratings = $string.prev('.pr-category-snippet__rating');
              var reviewsText = $string.text().replace(/\D/g, '');
              var noReviewsText = '';

              if (reviewsText.length) {
                $string.text('(' + reviewsText + ')');
              } else {
                noReviewsText =
                  site.translations.product.product_pr_no_reviews || 'Be the First to Review';
                $string.text(noReviewsText);
                $ratings.hide();
              }
            });
          }
        });
      });
      var config = {
        childList: true,
        subtree: true
      };

      $('.review-snippet', context).each(function () {
        observer.observe($(this)[0], config);
      });
      var self = this;
      var $mppPage = $('.mpp-container', context);

      if ($mppPage.length) {
        var $container = $('.js-product-brief__image-wrapper', context);

        self.isMobile = Unison.fetch.now().name === 'small';
        self.initAltImageCarousel($container);
        Unison.on('change', function () {
          self.isMobile = Unison.fetch.now().name === 'small';
          if (self.isMobile) {
            self.initAltImageCarousel($container);
          } else {
            if ($container.hasClass('slick-initialized')) {
              $container.unslick();
            }
          }
        });
      }
    },
    initAltImageCarousel: function ($container) {
      var isCr22 = Drupal.settings.global_variables && Drupal.settings.global_variables.cr22;
      var $mppGridCarousel = $container.closest('.js-mpp-carousel');
      var settings = {
        rtl: site.direction.isRTL,
        infinite: false,
        mobileFirst: true,
        arrows: true,
        speed: 300,
        slide: '.js-product-brief-image-link',
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: 'ondemand'
      };
      var self = this;

      if (isCr22) {
        settings.arrows = false;
        settings.swipe = $mppGridCarousel.length > 0 ? false : true;
        settings.dots = true;
      }

      // Init this carousel with our settings
      if (self.isMobile) {
        $container.not('.slick-initialized').slick(settings);
      }
    }
  };
})(jQuery);
